import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import backicon from "../../assets/icons/backicon.svg";
import plusIcon from "../../assets/icons/plusicon.svg";
import axonsImage from "../../assets/image/axons.svg";
import mastercard from "../../assets/icons/mastercard.svg";
import visacard from "../../assets/icons/visa.svg";
import jcbcard from "../../assets/icons/jcb.svg";
import expresscard from "../../assets/icons/aexpress.svg";
import editicon from "../../assets/icons/editicon.svg"
import DeleteIcon from "../../assets/icons/DeleteIcon";

import paymentAnimation from "../../assets/image/paymentprocess.gif"

import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Button,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Paper,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Modal,
  Divider,
  useTheme,
  RadioGroup,
  Radio,
  Avatar,
  CircularProgress,
  FormControl,
  FormLabel,
  Backdrop,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataContext from "../../DataContext";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProducts } from "../../slices/paymentSlice";
import { devicePrices } from "../Dashboard/Home";
import { useDeleteData, useGetData, usePatchData, usePostData } from "../../hooks/useApi";
import PaymentSuccess from "../../components/PaymentSuccess";
import { useSnackbar } from "../../hooks/Snackbar";
import EmptyOrder from "../Order/EmptyOrder";
import { formatCurrency } from "../Order/util";
import { logout } from "../../slices/authSlice";

const stripePromise = loadStripe("pk_test_51PTMBPP2lRiLb8rerMMomjxITWNJjPSfIRDUBrDqvvNHTcIpUB8Bqm9lFP9Ywx9aNKZ4oqhPF732Ci9sOo5QuGyE00QNmTA96f");
const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#535A6A',
      fontFamily: 'DM Sans, Arial, sans-serif',
      '::placeholder': { color: '#aab7c4' },
      padding: '10px 14px',
      backgroundColor: '#ffffff',
      borderRadius: '0.75rem',
      boxSizing: 'border-box',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    invalid: { color: '#fa755a' }
  }
};

const getCardIcon = (brand) => {
  const iconStyle = {
    width: '24px',
    height: '24px',
    marginRight: '8px',
    color: '#000',
  };

  switch (brand.toLowerCase()) {
    case 'visa':
      return <img src={visacard} style={iconStyle} />;
    case 'mastercard':
      return <img src={mastercard} style={iconStyle} />;
    case 'american_express':
      return <img src={expresscard} style={iconStyle} />;
    case 'discover':
      return <img src={visacard} style={iconStyle} />;
    case 'dinersclub':
      return <img src={visacard} style={iconStyle} />;
    case 'jcb':
      return <img src={jcbcard} style={iconStyle} />;
    default:
      return <img src={visacard} style={iconStyle} />;
  }
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  boxShadow: "1.5rem",
  minHeight: "30%",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column"
};
const buttonStyle = {
  color: "white",
  marginTop: "1rem",
  borderRadius: "0.75rem",
  width: "40%",
  height: "2.875rem",
  textTransform: "none",
  background: "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
  margin: "auto"
};
const typographyStyle = {
  fontsize: "12px",
  fontWeight: 500,
  color: "#535A6A",
  fontFamily: "DM Sans, Arial, sans-serif",
  p: 1
}

const AddressDialog = ({
  isEditing,
  isAddingNewAddress,
  currentAddress,
  handleDialogClose,
  handleSaveAddress,
  handleInputChange
}) => {
  const theme = useTheme();

  return (
    <Dialog open={isEditing || isAddingNewAddress} onClose={handleDialogClose}>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0.875rem",
      }}>
        <Typography sx={{ color: '#283246', fontWeight: 600, fontSize: '1.25rem' }}>
          {isEditing ? "Edit Address" : "Add New Address"}
        </Typography>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", padding: "0.875rem" }}>
        {["country", "state", "city", "zip_code", "street_address"].map((field, index) => (
          <Box key={index} sx={{ width: { xs: '100%', sm: '48%' }, marginBottom: "1rem" }}>
            <Typography sx={{ fontFamily: theme.typography.fontFamily, fontSize: "0.75rem", paddingBottom: "0.313rem", color: theme.palette.secondary.main }}>
              {field.charAt(0).toUpperCase() + field.slice(1).replace("_", " ")}
            </Typography>
            <TextField
              type="text"
              fullWidth
              value={currentAddress[field]}
              onChange={(e) => handleInputChange(field, e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0.75rem",
                  "& input": {
                    padding: "0.625rem",
                    height: "auto",
                    color: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamily
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.primary.borderColor
                },
              }}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", padding: "1rem" }}>
        <Button style={buttonStyle} onClick={handleSaveAddress}>
          Save
        </Button>
      </Box>
    </Dialog>
  );
};

const AddressSelectorDialog = ({
  openAddressSelector,
  addresses,
  selectedAddress,
  handleDialogClose,
  handleAddressChange,
  handleDelete
}) => {
  return (
    <Dialog open={openAddressSelector} onClose={handleDialogClose}>
    <Typography
      sx={{
        fontSize: '1.125rem',
        fontWeight: 500,
        color: '#535A6A',
        fontFamily: 'DM Sans, Arial, sans-serif',
        p: 2,
      }}
    >
      Select Address
    </Typography>
    <Divider />
    <DialogContent>
      <RadioGroup value={selectedAddress?.id || ''} onChange={handleAddressChange}>
        {addresses.map((address) => (
          <Box
            key={address.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              value={address.id}
              control={<Radio />}
              label={`${address.street_address}, ${address.city}, ${address.state} ${address.zip_code}, ${address.country}`}
              sx={{
                color: '#535A6A',
                fontFamily: 'DM Sans, Arial, sans-serif',
                fontSize: '0.8rem',
                fontWeight: 400,
                flex: 1, // Ensure the radio label takes up available space
              }}
            />
           <Box
                      onClick={() => handleDelete(address.id)}
                      sx={{
                        border: `1px solid #A9ACB4`,
                        width: "1.95rem",
                        height: "2.063rem",
                        padding: "0.438rem",
                        borderRadius: "0.313rem",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteIcon />
                    </Box>
          </Box>
        ))}
      </RadioGroup>
    </DialogContent>
    <DialogActions>
      {/* Other dialog actions can go here */}
    </DialogActions>
  </Dialog>  
  );
};

const CardsDialog = ({
  openCardSelector,
  existingCard,
  selectedCard,
  handleCardsDialogClose,
  handleCardSelection,
  handleCardDelete
}) => {
  return (
    <Dialog open={openCardSelector} onClose={handleCardsDialogClose}>
      <Typography sx={{ fontSize: '1.125rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif', p: 2 }}>
        Existing Card's
      </Typography>
      <Divider />
      <DialogContent>
        <RadioGroup value={selectedCard?.id} onChange={handleCardSelection}>
          {existingCard && existingCard?.map((card) => (
             <Box
             key={card.id}
             display="flex"
             alignItems="center"
             justifyContent="space-between"
           >
            <FormControlLabel
              key={card.id}
              value={card.id}
              control={<Radio />}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {getCardIcon(card.brand)}
                  <span>{`**** **** **** ${card.last4} (Exp: ${card.exp_month}/${card.exp_year}, ${card.brand})`}</span>
                </div>
              }
              sx={{ marginBottom: 1 }}
            />
            <Box
            onClick={() => handleCardDelete(card.id)}
            sx={{
              border: `1px solid #A9ACB4`,
              width: "1.95rem",
              height: "2.063rem",
              padding: "0.438rem",
              borderRadius: "0.313rem",
              cursor: "pointer",
            }}
          >
            <DeleteIcon />
          </Box>
          </Box>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        {/* Add any actions if needed */}
      </DialogActions>
    </Dialog>
  );
};

const AddCardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const theme = useTheme();
  const { clientSecret } = useContext(DataContext);
  const postData = usePostData();
  const getData = useGetData();
  const patchData = usePatchData();
  const deleteData = useDeleteData()
  const orderDetails = useSelector((state) => state.payment.selectedProducts);
  const clientSecrateKey = useSelector((state) => state.payment.clientSecrateKey);
  const devicePriceData = useSelector((state) => state.plan.productPrice);

  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [stripeIntent, setStripeIntent] = useState('');
  const [quantity, setQuantity] = useState(10);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [servicePlan, setServicePlan] = useState('');
  const [productsToBuy, setProductsToBuy] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [openAddressSelector, setOpenAddressSelector] = useState(false);
  const [openCardSelector, setOpenCardsSelector] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({ country: '', state: '', city: '', zip_code: '', street_address: '' });
  const [addresses, setAddresses] = useState([]);
  const [paymentIntentKey, setPaymentIntent] = useState('');
  const [orderPlaces, setOrderPlaced] = useState(false);
  const [existingCard, setExistingCard] = useState('');
  const [selectedCard, setSelectedCard] = useState(existingCard[0]);
  const [addNewCard, setAddNewCard] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit();
    }
  }, [clientSecret]);

  useEffect(() => {
    if (orderDetails.length === 0) {
      // If orderDetails is an empty array, setProductsToBuy with an empty array or appropriate value
      setProductsToBuy([]);
    } else if (orderDetails) {
      orderDetails.forEach(item => {
        if (item.type === "plan") {
          setServicePlan(item);
        } else if (item.type === "product") {
          setProductsToBuy(orderDetails);
        } else {
          setProductsToBuy(orderDetails);
        }
      });
    }
  }, [orderDetails]);
  

  const updateDevicePrices = (prices, deviceData) => {
    const updatedPrices = { ...prices };

    deviceData?.products?.forEach(product => {
      const { name, price } = product;
      const keyName = name.replace(" ", "");

      if (updatedPrices[keyName]) {
        Object.keys(updatedPrices[keyName]).forEach(key => {
          const quantity = parseInt(key, 10);
          updatedPrices[keyName][key].discountedPrice = price * quantity;
        });
      }
    });

    return updatedPrices;
  };

  const updatedDevicePrices = updateDevicePrices(devicePrices, devicePriceData);

  const handleClose = () => {
    setOpen(false);
  };

  const calculateCost = (quantity, type) => {
    return updatedDevicePrices[type][quantity].discountedPrice;
  };

  const handleClickOpen = (productName) => {
    setSelectedProduct(productName);
    const selectedOrder = orderDetails?.find(order => order.name === productName);
    setQuantity(selectedOrder?.quantity);
    setOpen(true);
  };

  const handleQuantityChange = (event) => {
    const newQuantity = event.target.value;
    setQuantity(newQuantity);
  };

  const handleUpdate = () => {
    const updatedOrderDetails = orderDetails
      .map((order) => {
        if (order.name === selectedProduct) {
          return {
            ...order,
            quantity: quantity,
            price: calculateCost(quantity, selectedProduct.replace(/\s+/g, '')),
          };
        }
        return order;
      })
      .filter((order) => order.quantity > 0);

    dispatch(setSelectedProducts(updatedOrderDetails));
    setOpen(false);
  };

  const getAddress = async () => {
    try {
      const { data } = await getData.mutateAsync({ endpoint: "/manage/addresses", data: '' });
      if (data) setAddresses(data);
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      console.error(error);
    }
  };

  const addAddress = async (newAddress) => {
    try {
      const { data } = await postData.mutateAsync({ endpoint: "/manage/create-addresses", data: newAddress });
      if (data){
        showSnackbar('Address added successfully', 'success');
        getAddress();
      } 
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");
      console.error(error);
    }
  };

  const editAddress = async (id) => {
    try {
      const { data } = await patchData.mutateAsync({ endpoint: `/manage/addresses/${id}`, data: currentAddress });
      if (data){
        showSnackbar('Address edited successfully', 'success');
        getAddress();
      } 
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");

      console.error(error);
    }
  };

  const deleteAddress = async (id) => {
    try {
      const { data } = await deleteData.mutateAsync({ endpoint: `/manage/addresses/${id}/delete`, data: '' });
      if (data){
        showSnackbar('Address deleted successfully', 'success');
        getAddress();
      } 
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");

      console.error(error);
    }
  };

  const deleteCard = async (id) => {
    try {
      const { data } = await deleteData.mutateAsync({ endpoint: `/manage/delete-card/${id}`, data: '' });
      if (data){
        showSnackbar('Card deleted successfully', 'success');
        getAddress();
      } 
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      showSnackbar((error?.response?.data?.message || error?.message) ||  'An error occurred', "error");

      console.error(error);
    }
  };

  const handleCardSelection = (event) => {
    const selectedCardId = event.target.value;
    const selectedCardObj = existingCard.find(card => card.id === selectedCardId);

    if (selectedCard && selectedCard.id === selectedCardId) {
      setSelectedCard(null);
    } else {
      setAddNewCard(false);
      setSelectedCard(selectedCardObj);
    }

    setOpenCardsSelector(false);
  };

  const getCards = async (id) => {
    try {
      const { data } = await getData.mutateAsync({ endpoint: `/manage/get-card`, data: '' });
      if (data) {
        setExistingCard(data);
      }
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      console.error(error);
    }
  };

  useEffect(() => {
    getAddress();
    getCards();
  }, []);

  const handleSaveAddress = () => {
    if (isEditing) {
      editAddress(currentAddress.id);
    } else if (isAddingNewAddress) {
      addAddress(currentAddress);
    }
    handleDialogClose();
  };

  const handleAddNewAddress = () => {
    setCurrentAddress({ country: '', state: '', city: '', zip_code: '', street_address: '' });
    setIsAddingNewAddress(true);
    setOpenAddressSelector(false);
  };

  const handleDialogClose = () => {
    setIsEditing(false);
    setIsAddingNewAddress(false);
    setOpenAddressSelector(false);
  };

  const handleAddressChange = (event) => {
    const selectedId = event.target.value;
    const address = addresses.find(addr => addr.id === selectedId);
    setSelectedAddress(address);
    setOpenAddressSelector(false);
  };

  useEffect(() => {
    if (addresses && selectedAddress) {
      const address = addresses.find(addr => addr.id === selectedAddress?.id);
      setSelectedAddress(address);
    } else if (!selectedAddress) {
      setSelectedAddress(addresses[0]);
    }
  }, [addresses]);

  const handleEditAddress = () => {
    if (selectedAddress) {
      setCurrentAddress(selectedAddress);
      setIsEditing(true);
    }
  };

  const openAddressPopup = () => {
    setOpenAddressSelector(true);
  };

  const openCardsPopup = () => {
    setOpenCardsSelector(true);
  };

  const handleCardsDialogClose = () => {
    setOpenCardsSelector(false);
  };

  const checkout = async (paymentMethod) => {
    try {
      setLoading(true);
      const { data } = await postData.mutateAsync({
        endpoint: "/manage/checkout",
        data: {
          "method_type": "card",
          "source_id": paymentMethod,
          "cart": { "products": productsToBuy, "plan": servicePlan },
          "address_id": selectedAddress.id,
        },
      });

      if (data) {
        if (data?.status === 'incomplete') {
          setPaymentIntent(data?.next_action?.client_secret);
        } else {
          setOrderPlaced(true);
        }
      }
    } catch (error) {
      if(error?.response?.status===401){
        showSnackbar("Oops! Something went wrong, and you've been logged out.","error")
        dispatch(logout())
        return
      }
      showSnackbar(error.message, 'error');
      console.log(error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
  // Get elements for card number, expiry, and CVV
  const cardNumberElement = elements.getElement(CardNumberElement);
  const cardExpiryElement = elements.getElement(CardExpiryElement);
  const cardCvcElement = elements.getElement(CardCvcElement);

  if (!cardNumberElement._complete) {
    showSnackbar("Card number is incomplete. Please enter a valid card number.", "error");
    return;
  }

  if (!cardExpiryElement._complete) {
    showSnackbar("Card expiry date is incomplete. Please enter a valid expiry date.", "error");
    return;
  }

  if (!cardCvcElement._complete) {
    showSnackbar("Card CVV is incomplete. Please enter a valid CVV.", "error");
    return;
  }


  // Start the loading animation
  setLoading(true)
        const { error, setupIntent } = await stripe.confirmCardSetup(clientSecrateKey, {
        payment_method: {
          card: cardNumberElement,
          billing_details: { name: name, email: email },
        },
      });

      if (error){

        setLoading(false)
        showSnackbar(error.message, 'error');
      }
      if (setupIntent.status === 'succeeded'){      
        setSuccess(true);
      } 
      else if (setupIntent.status === 'requires_action' || setupIntent.status === 'requires_confirmation') { }
      else throw new Error(`Unexpected status: ${setupIntent.status}`);

      setStripeIntent(setupIntent.payment_method);
      checkout(setupIntent.payment_method);
    } catch (error) {
      // showSnackbar(error.message, 'error');
      setLoading(false);
      console.error("Error:", error);
      setError(error.message);
    }
  };

  const nextAction = async () => {
    setLoading(true);
    const { error: nextActionError, paymentIntent } = await stripe.handleNextAction({
      clientSecret: paymentIntentKey
    });

    if (nextActionError) {
      console.log('error');
      setLoading(false);
    } else if (paymentIntent.status === 'succeeded') {
      setOrderPlaced(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentIntentKey) {
      nextAction();
    }
  }, [paymentIntentKey]);

  const handleInputChange = (field, value) => {
    setCurrentAddress(prev => ({ ...prev, [field]: value }));
  };

  const handleDeleteAddress = (id)=>{
    deleteAddress(id)
    handleDialogClose()
  }

  const handleCardDelete = (id)=>{
    deleteCard(id)
  }

  if (orderPlaces) {
    return (
      <PaymentSuccess />
    );
  }

  const handlePay = (e) => {
    e.preventDefault();
    if (selectedCard) {
      checkout(selectedCard?.id);
    } else {
      handleSubmit();
    }
  };

  const handleNewCard = () => {
    setAddNewCard(true);
    setSelectedCard('');
  };

  return (
    <Paper sx={{ p:2, minHeight: '95vh' ,borderRadius:'10px',boxShadow:'none'}}>
      <Grid item xs={12} sm={6} md={4}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton disableRipple onClick={() => navigate("/dashboard")} sx={{ mr: 1 }}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "1.375rem", color: "#283246" }}>Payment Method</Typography>
        </Box>
      </Grid>
      {orderDetails.length?<>
        <Grid item xs={12} sm={6} md={4}>
        <Box sx={{ display: 'flex',p:2,minHeight:'90vh'}}>
          <Box component="form" onSubmit={(e) => { handlePay(e); }} noValidate sx={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
  backdropFilter: 'blur(10px)',  mt: 1, width: '50%', p: 2,backdropFilter: 'blur(10px)', 
  borderRadius: '20px', borderRadius:'1rem',ml:5 }}>
            <Typography sx={{ fontsize: "12px", fontWeight: 500, color: "#535A6A", fontFamily: "DM Sans, Arial, sans-serif", p: 1 }}>
              Pay securely using your credit or debit card.
            </Typography>
            <Box sx={{ p: 0.5 }}>
              <img src={mastercard} width={65} height={45} />
              <img src={visacard} width={60} height={45} />
              <img src={jcbcard} width={60} height={45} />
              <img src={expresscard} width={60} height={45} />
            </Box>
            <Typography sx={{ fontsize: "12px", fontWeight: 500, color: "#535A6A", fontFamily: 'DM Sans, Arial, sans-serif', p: 1 }}>
              Choose from existing card
            </Typography>
            <Box sx={{ p: 2, width: '70%', borderRadius: '0.75rem', border: '1px solid #ced4da', mt: 1 }}>
              {selectedCard && (
                <RadioGroup value={selectedCard?.id} onChange={handleAddressChange} sx={{ p: 0.5 }}>
                  <FormControlLabel
                    key={selectedCard?.id}
                    value={selectedCard?.id}
                    control={<Radio />}
                    label={`**** **** **** ${selectedCard?.last4} (Exp: ${selectedCard?.exp_month}/${selectedCard?.exp_year}, ${selectedCard?.brand})`}
                    sx={{ color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif', fontSize: '0.8rem', fontWeight: 400 }}
                  />
                </RadioGroup>
              )}
              <Button sx={{ textTransform: 'none', color: '#535A6A', border: '1px solid #DDDEE1', borderRadius: '0.75rem' }} variant="outlined" onClick={openCardsPopup}>
                Select Existing Card
              </Button>
              <Button sx={{ textTransform: 'none', color: '#535A6A', border: '1px solid #DDDEE1', borderRadius: '0.75rem', ml: 2 }} variant="outlined" onClick={handleNewCard}>
                Add New Card
              </Button>
            </Box>
            {addNewCard && (
              <>
                <Grid item xs={12} sm={9} md={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography sx={typographyStyle}>Email</Typography>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="email"
                        error={!!errors.email}
                        helperText={errors.email}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                          width: "70%",
                          height: "0.5rem",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                            "& input": { padding: "0.8rem", height: "1rem" },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                      <Typography sx={typographyStyle}>Cardholder Name</Typography>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        placeholder="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!errors.name}
                        helperText={errors.name}
                        sx={{
                          width: "70%",
                          height: "0.5rem",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                            "& input": { padding: "0.8rem", height: "1rem" }
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ width: '70%', mt: 5 }}>
                  <Typography sx={typographyStyle}>Card Number</Typography>
                  <Box sx={{
                    '& .StripeElement': {
                      ...cardElementOptions.style.base,
                      border: '1px solid #ced4da'
                    },
                    '& .StripeElement--focus': {},
                    '& .StripeElement--invalid': {
                      borderColor: '#fa755a',
                    },
                  }}>
                    <CardNumberElement options={cardElementOptions} />
                  </Box>
                </Box>
                <Box container spacing={3} sx={{ mt: 2, display: 'flex', gap: 2, width: '70%' }}>
                  <Box item xs={6} sx={{ flex: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography sx={typographyStyle}>Expiration</Typography>
                    </Box>
                    <Box sx={{
                      '& .StripeElement': {
                        ...cardElementOptions.style.base,
                        border: '1px solid #ced4da'
                      },
                      '& .StripeElement--focus': {},
                      '& .StripeElement--invalid': {
                        borderColor: '#fa755a',
                      },
                    }}>
                      <CardExpiryElement options={cardElementOptions} />
                    </Box>
                  </Box>
                  <Box item xs={6} sx={{ flex: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography sx={typographyStyle}>CVC</Typography>
                    </Box>
                    <Box sx={{
                      '& .StripeElement': {
                        ...cardElementOptions.style.base,
                        border: '1px solid #ced4da'
                      },
                      '& .StripeElement--focus': {},
                      '& .StripeElement--invalid': {
                        borderColor: '#fa755a',
                      },
                    }}>
                      <CardCvcElement options={cardElementOptions} />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <Typography sx={{ fontsize: "12px", fontWeight: 500, color: "#535A6A", fontFamily: 'DM Sans, Arial, sans-serif', mb: 1, p: 1 }}>
              Choose Your Delivery Address
            </Typography>
            <Box sx={{ p: 2, width: '70%', borderRadius: '0.75rem', border: '1px solid #ced4da', mr: 4 }}>
              {selectedAddress ? (
                <>
                  <RadioGroup value={selectedAddress?.id || ''} onChange={handleAddressChange} sx={{ p: 0.5 }}>
                    <FormControlLabel
                      key={selectedAddress.id}
                      value={selectedAddress.id}
                      control={<Radio />}
                      label={`${selectedAddress.street_address}, ${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.zip_code}, ${selectedAddress.country}`}
                      sx={{ color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif', fontSize: '0.8rem', fontWeight: 400 }}
                    />
                  </RadioGroup>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                    <Button sx={{ textTransform: 'none', color: '#535A6A', border: '1px solid #DDDEE1', borderRadius: '0.75rem', display: 'flex', alignItems: 'center' }} onClick={handleAddNewAddress}>
                      <Avatar sx={{ bgcolor: "white", width: 12, height: 12, mr: 1 }}>
                        <img src={plusIcon} alt='plus' style={{ width: '100%', height: '100%' }} />
                      </Avatar>
                      Add New Address
                    </Button>
                    <Button sx={{ textTransform: 'none', color: '#535A6A', border: '1px solid #DDDEE1', borderRadius: '0.75rem', ml: 1 }} onClick={handleEditAddress} disabled={!selectedAddress}>
                      Edit Address
                    </Button>
                    <Button sx={{ textTransform: 'none', color: '#535A6A', border: '1px solid #DDDEE1', borderRadius: '0.75rem', ml: 1 }} variant="outlined" onClick={openAddressPopup}>
                      Change Address
                    </Button>
                  </Box>
                </>
              ) : (
                <Button sx={{ textTransform: 'none', color: '#535A6A', border: '1px solid #DDDEE1', borderRadius: '0.5rem', p: 1 }} variant="outlined" onClick={openAddressPopup}>
                  Select Address
                </Button>
              )}
            </Box>
              {/* Pay Button */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{
          mt: 3,
          width: '150px',
          borderRadius: '0.75rem',
          height: '45px',
          textTransform: 'none',
          position: 'relative',
          backgroundColor: loading ? '#f0ad4e' : 'primary.main',
          '&:disabled': {
            backgroundColor: '#d3d3d3',
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '&:hover': {
            backgroundColor: loading ? '#ec971f' : 'primary.dark',
          },
        }}
        disabled={loading}
      >
        {loading? (
          <CircularProgress
            size={24}
            sx={{
              color: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : (
          'Pay'
        )}
      </Button>

      {/* Modal for loading state */}
      <Modal
        open={loading}
        aria-labelledby="loading-modal"
        aria-describedby="loading-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }, // Dimmed background
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <img src={paymentAnimation} style={{ height: '150px', width: '150px', margin: '0 auto' }}/> 
          <Typography
            id="loading-description"
            sx={{
              fontFamily: 'DM Sans, Arial, sans-serif',
              fontSize: '1rem',
              color: '#535A6A',
              mt: 2,
            }}
          >
            Payment is processing. Please do not refresh the page or click the back button.
          </Typography>

          <Box sx={{ width: '100%', mt: 3 }}>
            <LinearProgress sx={{
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#F97E7E',
        },
      }} />
          </Box>
        </Box>
      </Modal>
          </Box>
          <AddressDialog
            isEditing={isEditing}
            isAddingNewAddress={isAddingNewAddress}
            currentAddress={currentAddress}
            handleDialogClose={handleDialogClose}
            handleSaveAddress={handleSaveAddress}
            handleInputChange={handleInputChange}
          />
          <AddressSelectorDialog
            openAddressSelector={openAddressSelector}
            addresses={addresses}
            selectedAddress={selectedAddress}
            handleDialogClose={handleDialogClose}
            handleAddressChange={handleAddressChange}
            openAddressPopup={openAddressPopup}
            handleDelete={handleDeleteAddress}
          />
          <CardsDialog
            openCardSelector={openCardSelector}
            existingCard={existingCard}
            selectedCard={selectedCard}
            handleCardsDialogClose={handleCardsDialogClose}
            handleCardSelection={handleCardSelection}
            openCardsPopup={openCardsPopup}
            handleCardDelete={handleCardDelete}
          />
          <Box item xs={12} md={5} sx={{ width: '50%', p: 1 }}>
           
              <Typography sx={{ fontSize: '1.125rem', fontWeight: 500, color: '#535A6A', fontFamily: 'DM Sans, Arial, sans-serif', textAlign: 'center' }}>
                Order Summary
              </Typography>
              {servicePlan && (
                <Paper sx={{p:2,background:'#FBFBFB',boxShadow:'none',borderRadius:'20px',width:'80%',margin:'auto'}}>
                    <Box sx={{
                      // maxWidth: '60%',
                      margin: 'auto',
                      border: '1px solid #DDDEE1',
                      boxShadow: '0px 1px 4px 0px #0000001C',
                      borderRadius: '0.75rem',
                      position: 'relative',
                      // paddingBottom: '56.25%',
                      height:200,
                      width:260,
                      overflow: 'hidden',
                    }}>
                      <img
                        src={servicePlan.image}
                        alt="Product"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          padding: '0.5rem'
                        }}
                      />
                    </Box>
                    <Typography sx={{
                      fontFamily: 'DM Sans, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '1rem',
                      color: '#535A6A',
                      textAlign: 'center',
                      p: 1
                    }}>
                      {servicePlan.name}
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'DM Sans, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '1rem',
                      color: '#283246',
                      textAlign: 'center',
                      p: 1
                    }}>
                      {formatCurrency(servicePlan.price)}/Month
                    </Typography>
                  <Box sx={{ padding: 2, border: '1px solid #E0E0E0', borderRadius: 2,maxWidth:'350px',margin:'auto' }}>
  <Grid container spacing={2}>
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">{servicePlan.name}</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">{servicePlan.quantity}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">Discount:</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">$0.00</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">Postage & Packing:</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">$00.00</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>Order Total:</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>{formatCurrency(servicePlan.price)}</Typography>
    </Grid>
  </Grid>
</Box>
                </Paper>
              )}
              {productsToBuy.length? (productsToBuy.length > 1 ? (
                 <Paper sx={{p:2,background:'#FBFBFB',boxShadow:'none',borderRadius:'20px',width:'80%',margin:'auto'}}>
                    <Box sx={{
                      maxWidth: 200,
                      margin: 'auto',
                      border: '1px solid #DDDEE1',
                      boxShadow: '0px 1px 4px 0px #0000001C',
                      borderRadius: '0.75rem',
                      position: 'relative',
                      height:120,
                      overflow: 'hidden',
                    }}>
                      <img src={axonsImage} alt="Products" width={'100%'} height={'100%'} />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography sx={{ fontFamily: 'DM Sans, Arial, sans-serif', fontWeight: 500, fontSize: '0.9rem', color: '#000000', textAlign: 'center', p: 0.5 }}>
                        Axons Micro & Axons Micro Pro
                      </Typography>
                      <Typography sx={{ fontFamily: 'DM Sans, Arial, sans-serif', fontWeight: 400, fontSize: '0.8rem', color: '#535A6A', textAlign: 'center', p: 0.5 }}>
                        Order the Axons Micro for versatile network integration and the Axons Micro Pro for superior connectivity and data management.
                      </Typography>
                      <Typography sx={{ fontFamily: 'DM Sans, Arial, sans-serif', fontWeight: 700, fontSize: '1.25rem', color: '#283246', textAlign: 'center', p: 0.5 }}>
                        {formatCurrency(orderDetails.reduce((total, order) => total + parseFloat(order.price), 0).toFixed(2))}
                      </Typography>
                    </Box>
                    <Box sx={{ maxWidth:'350px', margin: 'auto', textAlign: 'left', padding: 2, border: '1px solid #E0E0E0', borderRadius: 2 }}>
  {orderDetails.map((order, index) => (
    <Box key={index}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
        <Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>{order.name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton size="small" onClick={() => handleClickOpen(order.name)}  
           style={{
            width: '50.3px',
            height: '23px',
            borderRadius: '10px',
            border: "0.65px solid rgba(221, 222, 225, 1)",
            p:1   
          }}
          >
            <img src={editicon} alt="Edit" />
          </IconButton>
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ marginLeft: 1 }}>{order.quantity} Devices</Typography>
      </Box>
      {index < orderDetails.length - 1 && <Divider sx={{mb:1}} />}
    </Box>
     
  ))}

  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="textSecondary">Discount:</Typography>
    <Typography variant="body2" color="textSecondary">$0.00</Typography>
  </Box>
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="textSecondary">Subtotal:</Typography>
    <Typography variant="body2" color="textSecondary">{formatCurrency((orderDetails.reduce((total, order) => total + parseFloat(order.price), 0)).toFixed(2))}</Typography>
  </Box>
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="textSecondary">Postage & Packing:</Typography>
    <Typography variant="body2" color="textSecondary">$0.00</Typography>
  </Box>
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 700 }}>
    <Typography variant="body2" color="textSecondary">Order Total:</Typography>
    <Typography variant="body2" color="textSecondary">{formatCurrency((orderDetails.reduce((total, order) => total + parseFloat(order.price), 0)).toFixed(2))}</Typography>
  </Box>
</Box>



                  </Paper>
              ) : (
                <Paper sx={{p:2,background:'#FBFBFB',boxShadow:'none',borderRadius:'20px',width:'80%',margin:'auto'}}>
                  {orderDetails && productsToBuy.map((order, index) => (
                    <>
                      <Box sx={{ maxWidth: 200,
                      margin: 'auto',
                      border: '1px solid #DDDEE1',
                      boxShadow: '0px 1px 4px 0px #0000001C',
                      borderRadius: '0.75rem',
                      position: 'relative',
                      height:120,
                      overflow: 'hidden',}}>
                        <img key={index} src={order.image} alt="Product" width={'100%'} height={'100%'} />
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography sx={{ fontFamily: 'DM Sans, Arial, sans-serif', fontWeight: 500, fontSize: '0.9rem', color: '#000000', textAlign: 'center', p: 0.5 }}>{order.name}</Typography>
                        <Typography sx={{ fontFamily: 'DM Sans, Arial, sans-serif', fontWeight: 400, fontSize: '0.8rem', color: '#535A6A', textAlign: 'center', p: 0.5 }}>{order.description}</Typography>
                        <Typography sx={{ fontFamily: 'DM Sans, Arial, sans-serif', fontWeight: 700, fontSize: '1.25rem', color: '#283246', textAlign: 'center', p: 0.5 }}>
                          {formatCurrency(orderDetails.reduce((total, order) => total + parseFloat(order.price), 0).toFixed(2))}
                        </Typography>
                      </Box>
                      <Box sx={{ maxWidth:'350px', margin: 'auto', textAlign: 'left', padding: 2, border: '1px solid #E0E0E0', borderRadius: 2 }}>
  {productsToBuy.map((order, index) => (
    <Box key={index}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
        <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>{order.name}</Typography>
        <IconButton size="small" onClick={() => handleClickOpen(order.name)}   style={{
    width: '50.3px',
    height: '23px',
    borderRadius: '10px',
    border: "0.65px solid rgba(221, 222, 225, 1)",
    p:1   
  }}><img src={editicon}/></IconButton>
        <Typography variant="body2" color="textSecondary">{order.quantity} Devices</Typography>
      </Box>
      {index < productsToBuy.length - 1 && <Divider />}
    </Box>
  ))}
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="textSecondary">Discount:</Typography>
    <Typography variant="body2" color="textSecondary">$0.00</Typography>
  </Box>
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="textSecondary">Subtotal:</Typography>
    <Typography variant="body2" color="textSecondary">{formatCurrency((orderDetails.reduce((total, order) => total + parseFloat(order.price), 0)).toFixed(2))}</Typography>
  </Box>
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
    <Typography variant="body2" color="textSecondary">Postage & Packing:</Typography>
    <Typography variant="body2" color="textSecondary">$0.00</Typography>
  </Box>
  <Divider sx={{ my: 1 }} />
  <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 700 }}>
    <Typography variant="body2" color="textSecondary">Order Total:</Typography>
    <Typography variant="body2" color="textSecondary">{formatCurrency((orderDetails.reduce((total, order) => total + parseFloat(order.price), 0)).toFixed(2))}</Typography>
  </Box>
</Box>

                    </>
                  ))}
                </Paper>
              )):null}
           
          </Box>
        </Box>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Grid>
            <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0.875rem" }}>
              <Typography sx={{ color: '#283246', fontWeight: 600, fontSize: '1.25rem' }}>Update quantity</Typography>
              <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </Box>
            <Divider />
            <Box>
              <Box sx={{ margin: 'auto', width: '60%', justifyContent: 'center', padding: "0.2rem" }}>
                <Typography sx={{ color: '#535A6A', fontFamily: "DM Sans, Arial, sans-serif" }}>{selectedProduct}</Typography>
                <Select
                  value={quantity}
                  onChange={handleQuantityChange}
                  sx={{ "& .MuiOutlinedInput-notchedOutline": { border: `1px solid ${theme.palette.primary.borderColor}`, borderRadius: "0.7rem" }, width: '100%' }}
                >
                  {[0, 100, 200, 500].map((value) => (
                    <MenuItem key={value} value={value} sx={{ width: "100%", maxWidth: 400, fontSize: "0.813rem", color: "#535A6A", fontWeight: 500 }}>{value}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box sx={{ display: "flex", padding: "1rem" }}>
              <Button onClick={handleUpdate} style={buttonStyle}>Update</Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
      </>:<EmptyOrder title={"You haven't selected any products yet. Please add items to proceed."}/>}
    
    </Paper>
  );
};

const PaymentMethod = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const selectedProducts = useSelector((state) => state.payment.selectedProducts);
  const [validId, setValidId] = useState(id);

  useEffect(() => {
    if (!selectedProducts || selectedProducts.length === 0) {
      navigate("/dashboard"); // Redirect if no products are selected
    } else if (!selectedProducts.some(product => product.id === validId)) {
      // If the validId is no longer in the selectedProducts, update it to the first available product's id
      setValidId(selectedProducts[0].id);
      navigate(`/payment/${selectedProducts[0].id}`);
    }
  }, [selectedProducts, validId, navigate]);

 
 return(
 <Elements stripe={stripePromise}>
    <AddCardForm />
  </Elements>
 )
};

export default PaymentMethod;
