// src/slices/paymentSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedProducts: null,
    clientSecrateKey:null,
   
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },
    setClientSecrateKey: (state, action) => {
      state.clientSecrateKey = action.payload;
    },
  
  },
});

export const { setSelectedProducts ,setClientSecrateKey} = paymentSlice.actions;
export default paymentSlice.reducer;
