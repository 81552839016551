import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#283246",
      borderColor: "#97A1B6",
    },
    secondary: {
      main: "#535A6A",
    },

    common: {
      white: "#FFFFFF",
      lightGray: "#DDDEE1",
    },
    background: {
      main: "#F8F8F8",
    },
    borderColor: {
      primary: "#DDDEE1",
      secondary: "#A9ACB4",
    },
    status: {
      connected: "#0095FF",
      disconnected: "#FF6E8D",
      shipping: "#FF8900",
    },
  },
  typography: {
    fontFamily: "DM Sans, Arial, sans-serif",
  },
});

export default theme;
