import React from "react";
import { Box, Button, Card, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Successanim from "../assets/image/success.svg";
import backicon from "../assets/icons/backicon.svg";

const buttonStyleDownload = {
  color: "white",
  marginTop: "0.875rem",
  borderRadius: "0.75rem",
  width: "45%",
  height: "2.813rem",
  textTransform: "none",
  background:
    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
};

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleReturnToDashboard = () => {
    navigate("/dashboard/my-order");
  };

  return (
    <Card sx={{justifyContent: 'center', alignItems: 'center',boxShadow:'none'  }}>
     <Box >
     <IconButton
          onClick={() => navigate("/dashboard/my-order")}
          sx={{ alignSelf: "flex-start",ml:10,mt:5 }}
        >
          <img src={backicon} alt="back-icon" />
        </IconButton>
     </Box>
     <Box sx={{display:'flex',justifyContent: 'center', alignItems: 'center', minHeight: '85vh' }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 1,
          maxWidth: "600px",
          width: "100%",
          textAlign: "center",
        }}
      >
        
      
        <img
          src={Successanim}
          alt="Payment Success"
          style={{ width: "300px", marginBottom: "1rem" }}
        />
   
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: "1rem", color: "#283246", marginBottom: "1.5rem",fontFamily: "DM Sans, Arial, sans-serif", }}
        >
          Thank you! Your order has been successfully placed. A detailed receipt has been sent to your registered email.
          <br />
          Welcome to the next level of IoT innovation with Axons Lab.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReturnToDashboard}
            style={buttonStyleDownload}
          >
            My Orders
          </Button>
        </Box>
      </Box>
      </Box>
    </Card>
  );
};

export default PaymentSuccess;
