import React from "react";

export default function DeleteIcon() {
  return (
    <div>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4512 14.9931H4.54348C3.95242 14.9849 3.38637 14.7649 2.95851 14.3769C2.53066 13.9888 2.27255 13.4615 2.23579 12.9003L1.53579 2.7729C1.5322 2.69947 1.54395 2.6261 1.57035 2.55702C1.59676 2.48794 1.6373 2.42452 1.68963 2.37044C1.74355 2.31439 1.80882 2.26929 1.88152 2.23785C1.95423 2.20641 2.03287 2.18929 2.11271 2.1875H12.8819C12.9611 2.18733 13.0394 2.20265 13.112 2.23249C13.1846 2.26232 13.25 2.30605 13.3041 2.36096C13.3582 2.41586 13.3999 2.48076 13.4265 2.55163C13.4531 2.62249 13.4641 2.69781 13.4589 2.7729L12.7896 12.9003C12.7525 13.4665 12.49 13.998 12.0557 14.3868C11.6213 14.7755 11.0476 14.9923 10.4512 14.9931ZM2.75886 3.28512L3.35117 12.8344C3.37074 13.1224 3.50495 13.3924 3.72646 13.5895C3.94798 13.7867 4.24015 13.8961 4.54348 13.8954H10.4512C10.754 13.8943 11.0451 13.7843 11.2663 13.5876C11.4874 13.3908 11.6221 13.1217 11.6435 12.8344L12.2666 3.32171L2.75886 3.28512Z"
          fill="#535A6A"
        />
        <path
          d="M14.4231 3.28512H0.576923C0.423914 3.28512 0.277171 3.2273 0.168977 3.12438C0.0607827 3.02146 0 2.88186 0 2.73631C0 2.59076 0.0607827 2.45116 0.168977 2.34824C0.277171 2.24532 0.423914 2.1875 0.576923 2.1875H14.4231C14.5761 2.1875 14.7228 2.24532 14.831 2.34824C14.9392 2.45116 15 2.59076 15 2.73631C15 2.88186 14.9392 3.02146 14.831 3.12438C14.7228 3.2273 14.5761 3.28512 14.4231 3.28512Z"
          fill="#535A6A"
        />
        <path
          d="M9.8095 3.29286H5.19411C5.04172 3.29097 4.89613 3.23254 4.78837 3.13002C4.6806 3.02751 4.61918 2.88901 4.61719 2.74405V1.42691C4.62694 1.05139 4.7881 0.693814 5.06732 0.428201C5.34654 0.162588 5.72243 0.00927627 6.11719 0H8.88642C9.28774 0.00954713 9.66924 0.167941 9.9495 0.441371C10.2297 0.714802 10.3865 1.08161 10.3864 1.46349V2.74405C10.3844 2.88901 10.323 3.02751 10.2152 3.13002C10.1075 3.23254 9.96189 3.29097 9.8095 3.29286ZM5.77103 2.19524H9.23257V1.46349C9.23257 1.37616 9.1961 1.29241 9.13119 1.23065C9.06627 1.1689 8.97822 1.13421 8.88642 1.13421H6.11719C6.02538 1.13421 5.93734 1.1689 5.87242 1.23065C5.8075 1.29241 5.77103 1.37616 5.77103 1.46349V2.19524ZM9.8095 12.0738C9.65711 12.0719 9.51152 12.0135 9.40375 11.911C9.29599 11.8085 9.23456 11.67 9.23257 11.525V5.67104C9.23257 5.52548 9.29336 5.38589 9.40155 5.28297C9.50974 5.18005 9.65649 5.12223 9.8095 5.12223C9.9625 5.12223 10.1092 5.18005 10.2174 5.28297C10.3256 5.38589 10.3864 5.52548 10.3864 5.67104V11.525C10.3844 11.67 10.323 11.8085 10.2152 11.911C10.1075 12.0135 9.96189 12.0719 9.8095 12.0738ZM5.19411 12.0738C5.04172 12.0719 4.89613 12.0135 4.78837 11.911C4.6806 11.8085 4.61918 11.67 4.61719 11.525V5.67104C4.61719 5.52548 4.67797 5.38589 4.78616 5.28297C4.89436 5.18005 5.0411 5.12223 5.19411 5.12223C5.34712 5.12223 5.49386 5.18005 5.60206 5.28297C5.71025 5.38589 5.77103 5.52548 5.77103 5.67104V11.525C5.76904 11.67 5.70762 11.8085 5.59985 11.911C5.49209 12.0135 5.3465 12.0719 5.19411 12.0738ZM7.5018 12.0738C7.34941 12.0719 7.20383 12.0135 7.09606 11.911C6.98829 11.8085 6.92687 11.67 6.92488 11.525V5.67104C6.92488 5.52548 6.98566 5.38589 7.09386 5.28297C7.20205 5.18005 7.34879 5.12223 7.5018 5.12223C7.65481 5.12223 7.80155 5.18005 7.90975 5.28297C8.01794 5.38589 8.07873 5.52548 8.07873 5.67104V11.525C8.07673 11.67 8.01531 11.8085 7.90755 11.911C7.79978 12.0135 7.65419 12.0719 7.5018 12.0738Z"
          fill="#535A6A"
        />
      </svg>
    </div>
  );
}
