// src/hooks/useApi.js
import {useMutation, useQueryClient } from 'react-query';
import api from "../api"

// Hook for GET request
export const useGetData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const searchParam = typeof data.search === 'string' ? data.search : data.search?.search;
      // Reconstruct the query params
      const queryString = new URLSearchParams({
        page: data.page || '',
        search: searchParam || '',  // Fallback to empty string if no search provided
        status:data.status || ''
      }).toString();

      const response = await api.get(`${endpoint}?${queryString}`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getData');
      },
    }
  );
};
// Hook for POST request
export const usePostData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await api.post(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getData');
      },
    }
  );
};

export const usePatchData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await api.patch(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getData');
      },
    }
  );
};

export const useDeleteData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint }) => {
      const response = await api.delete(endpoint);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getData');
      },
    }
  );
};
