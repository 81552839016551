import { Box, Typography } from '@mui/material';
import React from 'react';
import orderempty from "../../assets/image/orderempty.svg"

const EmptyOrder = ({title}) => {
  return (
<Box sx={{display:'flex',justifyContent: 'center', alignItems: 'center',mt:10 }}>
 <Box
   sx={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     padding: 1,
     maxWidth: "600px",
     width: "100%",
     textAlign: "center",
   }}
 >
   <img
     src={orderempty}
     alt="No Results"
     style={{ width: "300px", marginBottom: "1rem" }}
   />
   <Typography
     variant="h4"
     gutterBottom
     sx={{ fontSize: "1rem", color: "#283246", marginBottom: "1.5rem",fontFamily: "DM Sans, Arial, sans-serif", }}
   >
  {title}
   </Typography>
   </Box>
 </Box>
  );
};

export default EmptyOrder;
