import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "./hooks/Snackbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { DataProvider } from "./DataContext";
import { QueryClient, QueryClientProvider } from 'react-query';

// Version detection and cache clearing
const appVersion = '1.0.2';  // Update this with each new deployment
const savedVersion = localStorage.getItem('appVersion');

if (savedVersion !== appVersion) {
  localStorage.setItem('appVersion', appVersion);
  window.location.reload(true);  // Force reload to clear cache
}

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <DataProvider>
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </SnackbarProvider>
  </DataProvider>
);

// Unregister service worker if applicable
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
