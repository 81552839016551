import React, { createContext, useContext, useState } from "react";
import CustomSnackbar from "../components/CustomSnackbar";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    variant: "success",
  });

  const showSnackbar = (message, variant = "success") => {
    // Immediately close the current Snackbar if open
    if (snackbar.open) {
      setSnackbar((prevSnackbar) => ({
        ...prevSnackbar,
        open: false,
      }));
    }

    // Open the new Snackbar after the current one is closed
    setTimeout(() => {
      setSnackbar({ open: true, message, variant });
    }, 100); // A small timeout to allow state update
  };

  const closeSnackbar = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onClose={closeSnackbar}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
