export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

export const formatTime = (dateString) => {
  const options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};