// src/slices/usersSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { updateUsers } = usersSlice.actions;
export default usersSlice.reducer;
