// src/api.js
import axios from 'axios';
import { store } from './store';

const api = axios.create({
  baseURL: 'https://api.axonslab.io/api', 
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});


api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.authToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;


