// src/slices/subscriberSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  assets:[]
};

const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    updateSubscribers: (state, action) => {
      state.data = action.payload;
    },
    updateAssets: (state, action) => {
      state.assets = action.payload;
    },
  },
});

export const { updateSubscribers, updateAssets} = subscriberSlice.actions;
export default subscriberSlice.reducer;
