import { Box } from "@mui/material";
import dashboardGif from "../assets/image/dashboard.gif";
import React from "react";

export default function LoadingUser() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <img src={dashboardGif} alt="loading-gif" />
    </Box>
  );
}
